<template>
    <Form @submit="handleSubmit" v-slot="{ errors }">
        <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1>Unbounce Integration</h1>
                    <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">
                                    Select Tags
                                    <a class="option" @click="createTags = !createTags">Create a Tag</a>
                                </label>
                                <div class="field_wpr" :class="{ 'has-error': errors.tag }">
                                    <Field autocomplete="off" name="tag" v-model="form.tags">
                                        <multiselect
                                            v-model="form.tags"
                                            mode="tags"
                                            label="name"
                                            value-prop="id"
                                            :searchable="true"
                                            :group-select="true"
                                            :options="tagsGroup"
                                            :groups="true"
                                            group-label="type"
                                            group-options="tags"
                                            :group-hide-empty="true"
                                            placeholder="Select Tags"
                                        >
                                            <template v-slot:grouplabel="{ group }">
                                                <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                            </template>
                                            <template v-slot:option="{ option }">
                                                <span>{{ option.name }}</span>
                                            </template>
                                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                                <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                                  {{ option.name }}
                                                  <span
                                                    v-if="!disabled"
                                                    class="multiselect-tag-remove"
                                                    @mousedown.prevent="handleTagRemove(option, $event)"
                                                  >
                                                      <span class="multiselect-tag-remove-icon"></span>
                                                  </span>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="tag" class="text-danger" />
                            </div>
                        </div>
                        <div v-if="createTags" class="form_grp">
                            <Form v-slot="{ errors }" ref="tag-form">
                                <div class="group_item">
                                    <label class="input_label">Create a New Tag</label>
                                    <div class="field_wpr has_suffix" :class="{ 'has-error': errors.name }">
                                        <Field autocomplete="off" type="text" v-model="tagForm.name" name="name" placeholder="Enter Tag Name.." rules="required" />
                                        <span class="suffix pointer" @click="handleTagCreate"><i class="fa fa-spinner fa-spin" v-if="tagLoader"></i> {{ tagLoader ? 'Creating' : 'Create' }}</span>
                                    </div>
                                    <ErrorMessage name="name" class="text-danger" />
                                </div>
                            </Form>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Webhook URL</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.webhook_url }">
                                    <Field autocomplete="off" name="webhook_url" v-model="form.webhook_url">
                                        <textarea v-model="form.webhook_url" name="webhook_url" cols="30" rows="10"></textarea>
                                    </Field>
                                </div>
                                <ErrorMessage name="webhook_url" class="text-danger" />
                            </div>
                        </div>
                        <div class="action_wpr">
                            <button class="btn cancel_btn"><i class="fa fa-spin fa-spinner" v-if="loader"></i> {{ loader ? 'Generating' : 'Generate Code' }}</button>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button :disabled="loader" type="button" class="btn save_btn" @click="copyWebhookUrl">Copy</button>
                </div>
            </div>
        </div>
    </Form>
</template>

<script>
    import { copyText } from 'vue3-clipboard'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Unbounce Integration',

        data () {
            return {
                form: {
                    tags: [],
                    webhook_url: '',
                },
                loader: false,
                createTags: false,
                tagForm: {
                    name: '',
                    type: 'leads',
                    uniqueId: 'bulk-action',
                },
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            user: {
                handler () {
                    const vm = this;

                    vm.resetForm();
                },
                deep: true
            },

            modelValue (value) {
                if (value) {
                    const vm = this;
                    document.body.classList.add('modal-open');

                    if (vm.tagsGroup.length == 0) {
                        vm.getTags();
                    }

                    vm.form       = { tags: [], webhook_url: '' };
                    vm.loader     = false;
                    vm.createTags = false;
                    vm.tagForm    = { name: '', type: 'leads', uniqueId: 'bulk-action' };

                }else{
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            tagsGroup: state => state.tagModule.tagsGroup,
            tagLoader: state => state.tagModule.tagLoader,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                getTags: 'tagModule/getTags',
                createTag: 'tagModule/createTag',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    tags: [],
                    webhook_url: '',
                };
            },

            handleTagCreate () {
                const vm = this;
                const form = vm.$refs['tag-form'];

                form.validate().then(({ valid }) => {
                    if (valid) {
                        vm.tagForm.setFieldError = form.setFieldError;

                        vm.createTag(vm.tagForm).then((result) => {
                            vm.createTags = false;
                            vm.tagForm.name = '';
                            vm.getTags();
                        });
                    }
                });
            },

            handleSubmit () {
                const vm = this;

                vm.form.webhook_url = `${process.env.VUE_APP_API_URL}/webhooks/unbounce/${vm.user.id}${vm.form.tags.length ? '/'+vm.form.tags.join(',') : ''}`;
            },

            copyWebhookUrl () {
                const vm = this;

                copyText(vm.form.webhook_url, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy webhook url!');
                    } else {
                        Toastr.success('Webhook URL has been copied successfully!');
                    }
                })
            },
        },
    }
</script>
